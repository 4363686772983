import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {  FaPrint } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';

import PrintSaleInvoice from '../Store/PrintSaleInvoice';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import { useStateContext } from '../Context/ContextProvider';





function AllDeletedSaleInvoices() {

 

  

const {currency}=useStateContext();
 
const [invoices,setInvoices]=useState([]);
const [totalPages, setTotalPages] = useState(1);

const [searchQuery,setSearchQuery]=useState('');
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState('');
const [serialStart, setSerialStart] = useState('');

const [printData, setPrintData] = useState([]);
const [isPrint,setIsPrint]=useState(false);
const [isClick,setIsClick]=useState('');
const [norecord,setNoRecord]=useState(false);
useEffect(() => {
  setSerialStart((currentPage - 1) * itemsPerPage + 1);
}, [currentPage, itemsPerPage]);


const handleSearchChange = (event) => {

    setSearchQuery(event.target.value);
    setCurrentPage(1);

  };

 
const handleReInvoice=(e,id,index)=>{
 
e.preventDefault();

setIsClick(index);
const data={
  'id':id
}

axios.get('/sanctum/csrf-cookie').then(response => {
axios.post('/api/deletedReinvoice/print',data).then(res=>{
  
  const printDatas={
    letter:res.data.cart,
    dates:res.data.dates,
    salestax:res.data.salestax,
    shipping:res.data.shipping,
    discount:res.data.discount,
    grandtotal:res.data.grandtotal,
    invoiceno:res.data.invoiceno,
    remainingbill:res.data.remainingbill,
    customer:res.data.customer,
    customeraddress:res.data.customeraddress,
    salesman:res.data.salesman,
    payment:res.data.payment,
    totalpay:res.data.totalpay,
    pbalance:res.data.pbalance,
    recovery:res.data.recovery,
    printingtime:res.data.printingtime,
    manualdate:res.data.manualdate,
    detail:res.data.detail,
    imagesrc:res.data.detail,
    entercash:res.data.entercash,
    paymentType:res.data.payment_type,
    mesg:res.data.mesg
    
          }
          setPrintData(printDatas);
          
          
            setIsPrint(true);



          });
        });



}

useEffect(()=>{


  if(isPrint){

 
    var div = document.getElementById('printablearea');

    // Create a window object.
    var win = window.open('', '', 'height=700,width=700'); // Open the window. Its a popup window.
    win.document.write(div.outerHTML);     // Write contents in the new window.
    win.document.close();
    setTimeout(function() {
     // win.print();
      //win.close();
    }, 2000);
  setIsPrint(false);
  setIsClick('');
  }

},[isPrint]);
useEffect(()=>{
    axios.get(`/api/alldeletedsaleinvoices?page=${currentPage}&search=${searchQuery}`).then(response=>{
      setInvoices(response.data.invoices.data);
      setTotalPages(response.data.invoices.last_page);
     setItemsPerPage(response.data.invoices.per_page);
if((response.data.invoices.data).length===0){

  setNoRecord(true);
}else{
  setNoRecord(false);
}

    })
   
    
   
    


  
  },[currentPage,searchQuery,isClick]);



    return (


   <div className='animated fadeInDown' style={{overflowX:'auto'}}>















<center><h1 style={{color:'red'}}>All Deleted Invoices </h1><hr/></center>
<Row className='g-2'>
  <div className='col-md-4 mb-2'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>
<div className='col-md-3'>
{invoices.length? '':<Puff stroke='red'/>}  {norecord?'No Matchig Record Found':null}
</div>
 



  
    
</Row>

   <div className='row' >
    <div className='col-12 table-responsive'>

    <Table  bordered hover size="sm" className='table ' >
    <thead >
        <tr>
        <th>Sr</th>
        <th>Customer Name</th>
        <th>Salesman Name</th>
        <th>Invoice No</th>
        <th>Total Amount</th>
        <th>GST/HST</th>
        <th>Paid Amount</th>
        <th>Cash Taken</th>
        <th>Cash Return</th>

        <th>Discount</th>
        
        <th>Net Due</th>
        <th>Date</th>
        <th>Type</th>
    <th>Action</th>
        </tr>
    </thead>
  <tbody>

 {invoices && invoices.map((item,index)=>(


 <tr key={item.id}  style={{color:'red'}}
 >

<td>{serialStart+index}</td>
<td>{item.name}{item.userid}</td>
<td>{item.salesman}{item.salesmanid}</td>
<td>{item.visitor_id}</td>
<td>{currency}{parseFloat(item.total).toFixed(2)}</td>
<td>{currency}{parseFloat(item.SalesTax).toFixed(2)}</td>
<td>{currency}{parseFloat(item.cash_received).toFixed(2)}{item.payment_type==='Cash'?<p style={{color:'blue'}}>Cash</p>:<p style={{color:'red'}}>Card</p> }</td>
<td>{currency}{parseFloat(item.entercash).toFixed(2)}</td>
<td>{currency}{parseFloat(item.entercash-item.cash_received).toFixed(2)}</td>
<td>{currency}{parseFloat(item.discount).toFixed(2)}</td>

<td>{currency}{parseFloat(item.due_amount).toFixed(2)}</td>
<td>{item.invoice_date}</td>
    <td>{item.type}</td>
 
<td>
    <button disabled={isClick===index?true:false} onClick={(e)=>handleReInvoice(e,item.visitor_id,index)} className='btn btn-primary' style={{marginLeft:'3px'}}>{isClick===index?<Puff width={15} height={10}/>:<FaPrint />} </button> 

</td>
       
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
    </div>
   
    </div> 
    
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>

      <div style={{ display: 'none' }} >
{
  
  isPrint  && <PrintSaleInvoice data={printData} />
  
  
  }
  </div>

</div>



  
  )
}

export default AllDeletedSaleInvoices
