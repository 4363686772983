import axios from 'axios';
import  { React,useState } from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from './Context/ContextProvider';
import Swal from "sweetalert2";
import Puff from 'react-loading-icons/dist/esm/components/puff';

function Login() {
const[isClick,setIsClick]=useState(false);
 const{setUser,setToken,setType,setUserId}= useStateContext();
const [Login,setLogin]=useState({
email:'',
password:'',
errorlist:[]
});
const handleInput=(e)=>{
e.persist();
setLogin({...Login,[e.target.name]:e.target.value});

}
const handleSubmit=(e)=>{

e.preventDefault();
setIsClick(true);
const data={
email:Login.email,
password:Login.password

}

axios.get('/sanctum/csrf-cookie').then(response => {
  axios.post('/api/login',data).then(res=>{
  
  if(res.data.status===200)
  {
    
setUser(res.data.username);
setToken(res.data.token);

setType(res.data.type);
setUserId(res.data.id);
     

  
  }else if(res.data.status===401){
  
  Swal.fire('warning',res.data.message);
  
  
  }else{
  
  setLogin({...Login,errorlist:res.data.validation_errors});
  
  }
setIsClick(false);

  });
  });


}
  return (
    <div className="login-signup-form animated fadeInDown" style={{transition:'ease-in-out',transitionDelay:'0s',transitionDuration:'1s'}}>
    <div className="form">
      <form onSubmit={handleSubmit}>
        <h1 className="title">Login To D.Desk</h1>

       
          <div>
            <p>          <span style={{color:"red"}}>{Login.errorlist.email}</span>
</p>
<p>          <span style={{color:"red"}}>{Login.errorlist.password}</span>
</p>
          </div>
      

        <input className='cinput' type="email" name='email' value={Login.email} onChange={handleInput} placeholder="Email"/>
        <input className='cinput' type="password" name='password' value={Login.password} onChange={handleInput} placeholder="Password"/>
        <button className="bttn btn-block">{isClick? <Puff width={23} height={23}/>:'Login'}  </button>
        <p className="message" style={{display:'none'}}>Not registered? <Link to="/signup" >Create an account</Link></p>
      </form>
    </div>
  </div>
  )
}

export default Login
