import axios from 'axios';
import React, { useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import Swal from 'sweetalert2';

function InvoiceSetting() {
  const [sloganHeading, setSloganHeading] = useState('');
  const [sloganSubheading, setSloganSubheading] = useState('');
  const [logo, setLogo] = useState(null);
  const [isClick, setIsClick] = useState(null);
  const [address, setAddress] = useState('');
  const [gst, setGst] = useState('');
  const [phone, setPhone] = useState('');

  async function handleSubmit(event) {
    

    event.preventDefault();
if(phone===''||gst===''||address===''||logo===null||sloganHeading===''||sloganSubheading===''){

  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title:'Please Fill All Data',
    showConfirmButton: false,
    timer: 1500
  })
  setIsClick(false);
return false;
}


  setIsClick(true);
    const formDatas = new FormData();
    formDatas.append('slogan_heading', sloganHeading);
    formDatas.append('slogan_subheading', sloganSubheading);
    formDatas.append('logo', logo);
    formDatas.append('address', address);
    formDatas.append('gst', gst);
    formDatas.append('phone', phone);
  
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  
    try {
      const response = await axios.post('api/post/system/invoice', formDatas, config);
      
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: response.data.message,
        showConfirmButton: false,
        timer: 1500
      })
      setIsClick(false);

    } catch (error) {
      console.log(error);
    }
  }
  


  
const handleImage=(e)=>{
setLogo(e.target.files[0]);


}




  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data" method='post'>
      
      <div className="row fadeInDown animated" >

    
    
    <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>INVOICE SETTING</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Company Name">
        <Form.Control type="text"  name="slogan_heading"  placeholder="heading"  value={sloganHeading}
        onChange={(e) => setSloganHeading(e.target.value)} />
      </FloatingLabel>
     
    </div>
    <div className='col-md-10 mt-4'>
    <FloatingLabel label="Company Address">
        <Form.Control type="text"  name="slogan_subheading" placeholder="sub heading"  value={sloganSubheading}
        onChange={(e) => setSloganSubheading(e.target.value)} />
      </FloatingLabel>
     
    </div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel label="Company GST">
        <Form.Control type="text"  name="gst" placeholder="gst"  value={gst}
        onChange={(e) => setGst(e.target.value)} />
      </FloatingLabel>
     
    </div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel label="Company Phone">
        <Form.Control type="text"  name="phone" placeholder="phone"  value={phone}
        onChange={(e) => setPhone(e.target.value)} />
      </FloatingLabel>
     
    </div>

    <div className='col-md-10 mt-4'>
    
        <Form.Control type="file"  name="slogan_subheading" placeholder="heading"  
        onChange={(e)=>handleImage(e)} />
     
     
    </div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel label="Invoice Message">
        <Form.Control type="text"  name="address" placeholder="Invoice Message" 
        value={address}
        onChange={(e) => setAddress(e.target.value)}/>
      </FloatingLabel>
     
    </div>


<div className='col-md-10 mt-2'>
<button type="submit" className='btn btn-primary btn-lg  btn-block'>{isClick?<Puff height={26} width={30}/>:'Save'}</button>

</div>


    </div></center></div>
    

    



    </form>
  );
}

export default InvoiceSetting;
