import React from 'react'

function NotFound() {
  return (
    <div>
      not found
    </div>
  )
}

export default NotFound
