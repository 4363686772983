import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';

function StockByStore() {
    const [stores,setStores]=useState('');
    const [defaultStore,setDefaultStore]=useState('');
    const [isClick,setIsClick]=useState(false);
useEffect(()=>{
axios.get('api/fetch/store').then(res=>{
setStores(res.data.store);
setDefaultStore(res.data.store[0]['id']);

})


},[]);
const handleSubmit=(e)=>{
e.preventDefault();
setIsClick(true);
const data={
    'storeid':defaultStore
}
axios({
  url: 'api/productbystore',
  method: 'POST',
  data:data,
  responseType: 'blob', // important
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'stockbystore.xlsx'); // or any other filename you prefer
    document.body.appendChild(link);
    link.click();
setIsClick(false);
});



}

  return (
    <div className='row fadeInDown animated'>
       <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Stock Report By Store</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Store Name">
      <Form.Select aria-label="Store Name" value={defaultStore} name='store_id' onChange={(e)=>setDefaultStore(e.target.value)}>
       
      {stores && stores.map(item => (
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
     
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-1 mt-4'>


<div className='col md'>
<button onClick={(e)=>handleSubmit(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff/>:'Fetch Stock'}  </button>

</div>
    </Row></div>
    </div></center>
    </div>
  )
}

export default StockByStore
