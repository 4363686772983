import React from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
  } from "recharts";
  
function CustomerChart(props) {
  return (
    <BarChart
    width={500}
    height={300}
    data={props.datas}
    margin={{
      top: 5,
      right: 0,
      left: 5,
      bottom: 5
    }}
    barSize={20}
  >
    <XAxis dataKey="customername" scale="point" padding={{ left: 10, right: 10 }} />
    <YAxis />
    <Tooltip />
    <Legend />
    <CartesianGrid strokeDasharray="3 3" />
    <Bar dataKey="totalsale" fill="#8884d8" background={{ fill: "#eee" }} />
  </BarChart>
  )
}

export default CustomerChart
