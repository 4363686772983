
import React, { useEffect, useState } from 'react'

import { FaMinusCircle, FaPlusCircle, FaTrash } from 'react-icons/fa'
import { useStateContext } from '../Context/ContextProvider'
import axios from 'axios';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Puff from 'react-loading-icons/dist/esm/components/puff';

function BillDetail() {
    const {items,setItems,userId}=useStateContext();
    const stringitems = JSON.parse(items);
    const [entercash,setEntercash]=useState('');
 const [discount,setDiscount]=useState(0);
 const [pBlanace, setPbalance] = useState(0);
 const [grandTotal, setGrandTotal] = useState(0);
 const [selectedOptionsector, setSelectedOptionsector] = useState(0);//selected means default
 const [selectedOptionshop, setSelectedOptionshop] = useState(0);
 const [subsectors, setsubsectors] = useState(null);
 const [shopId, setShopId] = useState(0);
 const [sectors,setsectors]=useState([]);
 const [shop, setshop] = useState([]);
 const [ppadvance,setPPadvance]=useState(0);
 const [recovery,setRecovery]=useState(0);
 const [remaining,setRemaining]=useState(0);
 const [balance,setBalance]=useState(0);
const [loading,setLoading]=useState(false);
const [isDiscountUpdating, setIsDiscountUpdating] = useState(false);

 const [salesTax, setSalesTax] = useState(0);

  
 const handleSaveReciept=(e)=>{


    setLoading(true);
  e.preventDefault();
  const data={
   letter:stringitems,
   sector:selectedOptionsector,
   subsector:selectedOptionshop,
   ppcell:shopId,
   manualdate:getToday(),
   recovery:recovery,
   ppadvance:ppadvance,
   salesmanid:userId,
   sales_tex_percent:salesTax,
   supergrandtotal:grandTotal,
   discount:discount
    }
  
   if(data.letter.length>0 && data.ppcell!==0 && data.manualdate? data.manualdate.length>0:0){
  
   
      axios.post('/api/post/reciept/entrysale',data).then(res=>{
        
   //   console.log(res.data);
   setItems([]); 
   setGrandTotal(0);   
   setEntercash(0);
        
  setLoading(false);
  Swal.fire({
    position: 'center',
    icon: 'success',
    title: 'Saved',
    showConfirmButton: false,
    timer: 1500
  })      
   
  
        });
      }else{
  
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'Fill All Fields',
          showConfirmButton: false,
          timer: 1500
        })
        setLoading(false);
      }
  
  
  }
  const handleDiscount=(e)=>{
   e.preventDefault();

   const updatedDiscount = parseFloat(e.target.value);
   setIsDiscountUpdating(true); // Set the flag to indicate manual update
   setDiscount(updatedDiscount);
   //setIsDiscountUpdating(false);
    }
    
    const handleEntercash=(e)=>{
        e.preventDefault();
        setEntercash(e.target.value);
        }

    useEffect(() => {
    
        const data={
          id:shopId,
          salesman_id:userId
          }
          
          if(shopId>0){
            axios.post('/api/shopkeeper/pbalance',data).then(res=>{
              setPbalance(res.data);
              
              });
          }else{
    
            setPbalance(0);
          }
      }, [shopId,stringitems,userId])
    const fetchPbalance=(event)=>{
        event.preventDefault();
        setShopId(event.target.value);
      
        }

        
    useEffect(()=>{
        axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`/api/getsectors`).then(res=>{
        setsectors(res.data.sectors);
        setSelectedOptionsector(res.data.sectors[0]['id']);
      
      });
  
        });
   
      },[]);
      useEffect(() => {
        if (selectedOptionsector) {
          
          axios.get(`/api/getsubsectors/${selectedOptionsector}`)
          .then(response => {
            setsubsectors(response.data.subsectors);
            
          })
          .catch(error => {
            console.error(error);
          });
        }
       
      },[selectedOptionsector]);
      useEffect(()=>{
  
        if(selectedOptionshop){
      
          axios.get(`/api/getshop/${selectedOptionshop}`)
          .then(response => {
        
            const sortedShpos = [...response.data.shop].sort((a, b) => a.name.localeCompare(b.name));
            setshop(sortedShpos);
      
          })
          .catch(error => {
            console.error(error);
          });
        }
        },[selectedOptionshop])
    
    
    
      useEffect(() => {
        if (stringitems !== null) {
          const sumgrandtotal = stringitems.reduce((acc, item) => {
            const value = parseFloat(item.unitsaleprice * item.qty);
            return isNaN(value) ? acc : acc + value;
          }, 0);
          setGrandTotal(sumgrandtotal.toFixed(2));
      
          const totaldiscount = stringitems.reduce((acc, item) => {
            const value = parseFloat(item.subtotaldiscount);
            return isNaN(value) ? acc : acc + value;
          }, 0);
      
          if (!isDiscountUpdating) {
            setDiscount(parseFloat(totaldiscount).toFixed(2));
          }
        }
      }, [stringitems, isDiscountUpdating]);
    

    useEffect(() => {
 
 const cashenter=entercash===''?0:entercash;
        if(parseFloat(cashenter) > (parseFloat(grandTotal)-parseFloat(discount))){
          const afterdiscount=parseFloat(pBlanace)+parseFloat(grandTotal)-parseFloat(cashenter)-parseFloat(discount);
         
          setRemaining(0);
          setPPadvance((parseFloat(grandTotal)-parseFloat(discount)).toFixed(2));
        setRecovery((parseFloat(cashenter)-(parseFloat(grandTotal)-parseFloat(discount))).toFixed(2));
        setBalance(afterdiscount.toFixed(2));
        }else{
          const afterdiscount=parseFloat(pBlanace)+parseFloat(grandTotal)-parseFloat(cashenter)-parseFloat(discount);
         
          setRemaining(afterdiscount.toFixed(2));
          setPPadvance(parseFloat(cashenter));
        setRecovery(0);
        setBalance(afterdiscount.toFixed(2));
        
        }
        
        
          },[entercash,pBlanace,discount,stringitems,grandTotal]);
    function getToday() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();
        // add leading zero to month and day if they're less than 10
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
        return `${year}-${month}-${day}`; // return date in YYYY-MM-DD format
      }
      
    const handleSubTotalDiscount=(id,event)=>{
        const newItems = [...stringitems];
        const index = newItems.findIndex(item => item.id === id);
      newItems[index].subtotaldiscount=event.target.value;
      const discountvalue=event.target.value;
      //const total=parseFloat(newItems[index].qty*newItems[index].unitsaleprice).toFixed(2);
     // const disval=total-discountvalue;
     // const disper=(((total-disval)/total)*100).toFixed(2);
      //newItems[index].discountpercent=disper;

      //newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(discountvalue)).toFixed(2);
      newItems[index].discountpercent=discountvalue;
      
      setItems(newItems);  
      
        
      }
     
    const handleUnitSalePriceChange = (id, event) => {
        const newItems = [...stringitems];
        const index = newItems.findIndex(item => item.id === id);
        newItems[index].unitsaleprice = event.target.value;
        const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*newItems[index].discountpercent/100;
        newItems[index].subtotaldiscount=parseFloat(subtotaldis).toFixed(2);
        newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(subtotaldis)).toFixed(2);
  
  
        setItems(newItems);
      };

      const handleRemove = (id) => {
    
        setItems(stringitems.filter((item) => item.id !== id));
      }; 
      const handleQtyIncre = (id) => {

     
        const newItems = [...stringitems];
        const index = newItems.findIndex(item => item.id === id);
        newItems[index].qty = newItems[index].qty+1;
     //active these lines for percentage discount
        //const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*newItems[index].discountpercent/100;
        //newItems[index].subtotaldiscount=parseFloat(subtotaldis).toFixed(2);
        //newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(subtotaldis)).toFixed(2);
         newItems[index].subtotaldiscount=(newItems[index].qty*newItems[index].discountpercent);
  
        if(newItems[index].remaining>=newItems[index].qty){
          setItems(newItems);
  
        }
        else{
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Store Does Not Have Enough Items',
            showConfirmButton: false,
            timer: 1500
          })
        }
  
       
  
      };

      const handleQtyDec= (id) => {

     
        const newItems = [...stringitems];
        const index = newItems.findIndex(item => item.id === id);
        newItems[index].qty = newItems[index].qty-1;
     
        //const subtotaldis=(newItems[index].qty*newItems[index].unitsaleprice)*newItems[index].discountpercent/100;
        //newItems[index].subtotaldiscount=parseFloat(subtotaldis).toFixed(2);
        //newItems[index].grandtotal=parseFloat((newItems[index].qty*newItems[index].unitsaleprice)-(subtotaldis)).toFixed(2);
        newItems[index].subtotaldiscount=(newItems[index].qty*newItems[index].discountpercent);
  
        if(newItems[index].qty >0){
          setItems(newItems);
  
        }
     
          else{
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Not Allowed Below 1',
              showConfirmButton: false,
              timer: 1500
            })
          }
      
  
       
  
      };
  return (

  
<div style={{width:'100vw'}}>
<label style={{backgroundColor:'darkblue',color:'white',width:'100vw',padding:'6px'}}>Select Customer</label>
    <div style={{display:'flex',justifyContent:'space-around'}}>

<div>
    <select style={{width:'110px'}} onChange={e => setSelectedOptionsector(e.target.value)} value={selectedOptionsector} className='form-control'>
        
        <option  >Select Sector</option>
{sectors.map(({id,name})=>(

<option key={id} value={id} >{name}</option>
))}
    </select>
</div>
<div>
    <select style={{width:'110px'}} onChange={e => setSelectedOptionshop(e.target.value)} value={selectedOptionshop}  className='form-control'>
        <option> SubSector</option>
        {subsectors && subsectors.map(item => (
  <option key={item.id} value={item.id}>
    {item.name}
  </option>
))}
  
    </select>
</div>
<div>
    <select style={{width:'110px'}}  onChange={fetchPbalance}  className='form-control'>
        <option> Customer</option>
        {shop && shop.map(item => (
  <option key={item.user_id} value={item.user_id}>
    {item.name}
  </option>
))}
    </select>
</div>

</div>

{stringitems && stringitems.map((item,index)=>(
    <div className='billdetail' key={item.id}>

  


     
     <div className='left'>
<div>
    Name: {item.name}  
</div>
<div style={{color:'red'}}>
    Price: <input value={item.unitsaleprice} onChange={(e)=>handleUnitSalePriceChange(item.id,e)}  style={{width:'100px',maxWidth:'100px',height:'25px',marginBottom:'5px',marginTop:'5px'}}></input>
    <FaTrash style={{color:'red',fontSize:'30px'}} onClick={(e)=>handleRemove(item.id)}/>
</div>
<div>
    Disc.: <input value={item.subtotaldiscount} onChange={(e)=>handleSubTotalDiscount(item.id,e)} style={{width:'100px',maxWidth:'100px',height:'25px'}}></input>
</div>

     </div>
<div>
<div style={{color:'green',fontSize:'20px',marginTop:'24px'}}>
    Total:{parseFloat(item.unitsaleprice*item.qty).toFixed(2)} 
</div>
  </div>

<div className='right'>
<div>
<FaPlusCircle style={{fontSize:'30px',color:'red'}} onClick={()=>handleQtyIncre(item.id)}/>
</div>
<div>
    <span style={{marginLeft:'10px'}}>{item.qty}</span>
</div>
<div>
<FaMinusCircle style={{fontSize:'30px',color:'orange'}} onClick={()=>handleQtyDec(item.id)}/>
</div>
</div>

    </div>   ))}

<label style={{backgroundColor:'darkblue',width:'100vw',padding:'6px',color:'white'}}> Grand Total</label>
<div style={{width:'100vw',padding:'10px',display:'flex',justifyContent:'space-between'}}>
    <div>
        <p>Grand Total=</p>
    <p>  Total Dis=</p>
    <p> Pre Balance=</p>
    <p>Recovery=</p>
    <p style={{display:'none'}}>SalesTax=</p>
    <p>Balance=</p>
    <p>Remaining=</p>
  
   
    
    </div>
    <div>
        <p>{grandTotal}</p>
        
         <input type='number' value={discount} name='discount' onChange={(e)=>handleDiscount(e)} style={{width:'80px',maxWidth:'80px'}}></input>

        <p> {pBlanace}</p>
        <p> {recovery} </p>
        <p style={{display:'none'}}> {salesTax} </p>
        <p>{balance}</p>
        <p> {remaining} </p>
    
    
   
   
    </div>

</div>
<label style={{backgroundColor:'darkblue',color:'white',width:'100vw',padding:'6px',display:'none'}}>Sales Tax</label>

<input style={{padding:'10px',display:'none'}} className='form-control' type='number' name='entersalestext' value={salesTax} onChange={(e)=>setSalesTax(e.target.value)}></input>

<label style={{backgroundColor:'darkblue',color:'white',width:'100vw',padding:'6px'}}>Enter Cash</label>

<input style={{padding:'10px'}} className='form-control' type='number' name='entercash' value={entercash} onChange={handleEntercash}></input>

<Button className='btn btn-block btn-success mb-2' onClick={handleSaveReciept}> {loading? <Puff stroke="#98ff98" height={26} width={30}   speed={.75}  />:'Save'} </Button>
    </div>

  )
}

export default BillDetail
