import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../Views/Context/ContextProvider'

function GuestLayout() {
    const {token}=useStateContext()

    if(token){
    
    return <Navigate to='/'/>
    
    }
  return (
    <div id="guestLayout">
    <Outlet />
  </div>
  )
}

export default GuestLayout
