import { Link } from 'react-router-dom'

import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';

import OffcanvasBody from 'react-bootstrap/OffcanvasBody'
import axios from 'axios';
function Sidebar(props) {

    const handleClose=()=>{
       
    props.onChildOffcanClose(false);
    
     }
const handleExportUser=()=>{
 
  axios({
    url: 'api/export/user/excel',
    method: 'GET',
    responseType: 'blob', // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users.xlsx'); // or any other filename you prefer
    document.body.appendChild(link);
    link.click();


  });

}

const handlePbalanceReport=()=>{
 
  axios({
    url: 'api/pbalance',
    method: 'get',
    responseType: 'blob', 
    
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
  handleClose();
  });



}
const handleOverallRecievableBySalesman=()=>{
 
 


  axios({
    url: 'api/overallrecievablebysalesman',
    method: 'get',
    responseType: 'blob', 
    
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
  handleClose();
  });



}
     



  return (
    <div >
       <Offcanvas show={props.show} className='animated fadeInDown' onHide={handleClose} placement='end' scroll='false'  >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title >Reports Section</Offcanvas.Title>
        </Offcanvas.Header>
        <OffcanvasBody>
        <Accordion>
        <Accordion.Item eventKey="8">
        <Accordion.Header>Reports</Accordion.Header>
        <Accordion.Body>
        <Link to='#'  style={{textDecoration:'none',color:'red',cursor:'pointer',fontSize:'20px'}} title='Paid'>Customers Gst Tax (Paid) </Link><hr></hr>
        
        <Link to='/allsaleinvoices' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>All Sale Invoices</Link><hr></hr>
       <Link to='/alldeletedsaleinvoices' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>All Deleted Sale Invoices</Link><hr></hr>
       <Link  onClick={handleClose}  to='all/purchase/invoices' style={{textDecoration:'none',color:'black',cursor:'pointer'}}>All Purchase Invoices</Link><hr/>
       
        <Link to='/dailybrandsalereport' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Daily Sale Report</Link><hr></hr>
       
       <Link onClick={handleExportUser}   style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Export Users Detail In Excel</Link><hr></hr>
       <Link to='stockbystore' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Closing Stock</Link><hr></hr>
       <Link to='stockstatus'  onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Stock Status</Link><hr></hr>
  
       <Link to={'ledger'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Client Ledger</Link><hr></hr>
       <Link to={'duelist'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Due/Recovery List</Link><hr></hr>
       <Link  onClick={handlePbalanceReport} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Overall Reieveable By Customer</Link><hr></hr>
       
       <Link onClick={handleOverallRecievableBySalesman} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Overall Recieveable By Salesman</Link><hr></hr>
       <Link to={'batchlistinvoices'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Batch List Invoices</Link><hr></hr>
       <Link to={'sectorsalepbalance'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Region Sale With Previous Balance</Link><hr></hr>
       <Link to={'subsectorsalepbalance'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Location Sale With Previous Balance</Link><hr></hr>
       <Link to={'salesummerybybrand'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Profit Report By Sale</Link><hr></hr>
       <Link to={'/due/sector/report'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Region Due Report</Link><hr></hr>
       <Link to={'/brand/purchase/report'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}> Purchase Report</Link><hr></hr>
      
     
        <Link to={'/monthlyexpensereport'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Monthly Expense Report</Link>
       
        
        
       
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="0">
        <Accordion.Header>Registration Module</Accordion.Header>
        <Accordion.Body>
       
       <Link to={'/userslist'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Users</Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Bank Detail</Accordion.Header>
        <Accordion.Body>
        <Link to='openingbalance' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Opening Balance</Link><hr></hr>
       <Link to={'recievepayment'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Recieve Payments</Link>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header>Company Detail</Accordion.Header>
        <Accordion.Body>
        <Link to={'systemstore'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Store</Link><hr></hr>
       <Link to={'systeminvoice'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Invoice Detail</Link>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Location Detail</Accordion.Header>
        <Accordion.Body>
        <Link to={'addsector'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Region</Link><hr></hr>
       <Link to={'addsubsector'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add City</Link><hr></hr>
       
       <Link to={'assignsector'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Assign City To Customer</Link>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>Products Managment</Accordion.Header>
        <Accordion.Body>
        <Link to="/add/category" onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Category</Link><hr></hr>
        <Link to='/add/product' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Product</Link><hr></hr>
       <Link to='/gen/barcode' onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Gen Barcode</Link><hr></hr>


       

        </Accordion.Body>
      </Accordion.Item>
     

      <Accordion.Item eventKey="6">
        <Accordion.Header>Purchase Managment</Accordion.Header>
        <Accordion.Body>
        <Link onClick={handleClose} to={'/purchase/entry/invoice'} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Purchase Invoice</Link><hr></hr>
       <Link  onClick={handleClose}  to='all/purchase/invoices' style={{textDecoration:'none',color:'black',cursor:'pointer'}}>All Purchase Invoices</Link>
       
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header> Special Invoices</Accordion.Header>
        <Accordion.Body>
        <Link to={'autoname'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Special Invoice Names</Link><hr></hr>
       <Link to={'settingautoname'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Setting Special Invoice</Link>
       
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header>Expense Sheet</Accordion.Header>
        <Accordion.Body>
        <Link to={'/expensesheet'} onClick={handleClose} style={{textDecoration:'none',color:'black',cursor:'pointer'}}>Add Monthly Expense</Link>
        
        </Accordion.Body>
        

      </Accordion.Item>

      
      


    </Accordion>
        </OffcanvasBody>
      </Offcanvas>
    </div>
  )
}

export default Sidebar
