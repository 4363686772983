
import React, {  useEffect, useState } from 'react'
import Barcode from 'react-barcode';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import '../Customcss/table.css';
import Row from 'react-bootstrap/Row';
function ProductBarcode() {
    const [totalPages, setTotalPages] = useState(1);
    const [products,setProducts]=useState([]);
const [searchQuery,setSearchQuery]=useState('');
const [currentPage, setCurrentPage] = useState(1);


const handlePrint = (id) => {
  var div = document.getElementById('printbarcode' + id);

  // Function to generate and store content
  function generateContent(counter) {
    if (counter >= 18) {
      printAllContent();
    } else {
      const contentDiv = document.createElement('div');
      contentDiv.innerHTML = div.innerHTML; // Clone the content

      // Ensure that the content is displayed inline or inline-block
      contentDiv.style.display = 'inline-block'; // You can adjust this as needed
      contentDivs.push(contentDiv);

      generateContent(counter + 1);
    }
  }

  // Function to print all stored content
  function printAllContent() {
    var win = window.open('', '', 'height=700,width=700'); // Open the window. It's a popup window.
    
    contentDivs.forEach((content) => {
      win.document.write(content.outerHTML); // Write contents in the new window.
    });

 
    setTimeout(function () {
      win.print();
      win.close();
    }, 2000);
  }

  // Array to store the repeated content
  const contentDivs = [];

  // Start the process by generating content
  generateContent(0);
};


const handleSearchChange = (event) => {

  setSearchQuery(event.target.value);
  setCurrentPage(1);
};
useEffect(()=>{
 
    axios.get(`/api/view/products?page=${currentPage}&search=${searchQuery}`).then(response=>{
      setProducts(response.data.products.data);
      setTotalPages(response.data.products.last_page);
    })
   
    
  
  },[currentPage,searchQuery]);
  
  
  return (
    <Row className='g-1' >

<div className='col-md' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>

<Row className='g-2'>
  <div className='col-md-4 mb-1'>
  <input type="text" className='form-control col-md' placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

  </div>




  
    
</Row>

    
    
    <Table striped bordered hover size="sm"  >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Name</th>
        <th>Alias</th>
       
     
        <th>Barcode</th>
            <th>Print</th>
        </tr>
    </thead>
  <tbody >
 {products && products.map((item,index)=>(


 <tr key={item.id} 
 >

    <td>{index+1}</td>
    <td >{item.name}</td>
    <td >{item.alias}</td>
  
      <td   id={'printbarcode'+item.id}> 
      <Barcode value={item.alias} />

      </td>
      <td  style={{textAlign:'center',verticalAlign:'middle'}} > 
  <button onClick={(e)=>handlePrint(item.id)} className='btn  btn-primary pl-50'>Print</button>

      </td>
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div>

</Row>
  );
}

export default ProductBarcode;
