import React from 'react'
import { FaBars, FaCashRegister, FaFileInvoice, FaFunnelDollar, FaSalesforce } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function Reports() {
  return (
    <div className='categories row animated fadeInDown'>

<div className='reports col-md-4  animated fadeInDown' >
        <div>
        <Link to={`/myinvoices`}  style={{textDecoration:'none',color:'red',fontSize:'40px'}}>
<center>
<FaFileInvoice/></center>

</Link> 
        </div>

<hr/>
<div>
<center> <p style={{color:'blue',fontSize:'20px'}}>My Invoices</p></center>
    
</div>
</div>
      <div className='reports col-md-4  animated fadeInDown' >
        <div>
        <Link to={`/recievepayment`}  style={{textDecoration:'none',color:'purple',fontSize:'40px'}}>
<center>
<FaCashRegister/></center>

</Link> 
        </div>
<hr/>

<div>
<center><p style={{color:'blue',fontSize:'18px'}}>Recieve Payment</p></center>
    
</div>
</div>
<div className='reports col-md-4  animated fadeInDown' >
        <div>
        <Link to={`/batchlistinvoices`}  style={{textDecoration:'none',color:'red',fontSize:'40px'}}>
<center>
<FaFileInvoice/></center>

</Link> 
        </div>
<hr/>

<div>
<center> <p style={{color:'blue',fontSize:'20px'}}>Daily Invoices</p></center>
    
</div>
</div>

<div className='reports col-md-4  animated fadeInDown' >
        <div>
        <Link to={`/dailybrandsalereport`}  style={{textDecoration:'none',color:'gray',fontSize:'40px'}}>
<center>
<FaSalesforce/></center>

</Link> 
        </div>
<hr/>

<div>
<center> <p style={{color:'blue',fontSize:'20px'}}>Daily Brand</p></center>
    
</div>
</div>

<div className='reports col-md-4  animated fadeInDown' >
        <div>
        <Link to={`/salesummerybybrand`}  style={{textDecoration:'none',color:'brown',fontSize:'40px'}}>
<center>
<FaBars/></center>

</Link> 
        </div>

<hr/>
<div>
<center> <p style={{color:'blue',fontSize:'20px'}}>Sale By Brands</p></center>
    
</div>
</div>


<div className='reports col-md-4  animated fadeInDown' >
        <div>
        <Link to={`/sectorsalepbalance`}  style={{textDecoration:'none',color:'red',fontSize:'40px'}}>
<center>
<FaFunnelDollar/></center>

</Link> 
        </div>

<hr/>
<div>
<center> <p style={{color:'blue',fontSize:'20px'}}>Sector  Balance</p></center>
    
</div>
</div>
    </div>
  )
}

export default Reports
