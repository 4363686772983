import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import axios from 'axios';
import { useStateContext } from '../Context/ContextProvider';
function BatchListInvoices() {
    const [isClick,setIsClick]=useState(false);
    const [defaultsalesman,setDefaultSalesman]=useState('');
    const [defaultsector,setDefaultSector]=useState('');
    const [salesman,setSalesman]=useState([]);
    const [sector,setSector]=useState([]);
    const [manualdate,setManualDate]=useState('');
    const [manualdateto,setManualDateTo]=useState('');
    const {type,userId}=useStateContext();
useEffect(()=>{
axios.get('/api/getsectors').then(res=>{

setSector(res.data.sectors);
setDefaultSector(res.data.sectors[0]['id']);

})


},[])
useEffect(()=>{
    axios.get('/api/getsalesman').then(res=>{
    
    setSalesman(res.data.salesman);
    setDefaultSalesman(res.data.salesman[0]['id']);
    
    })
    
    
    },[])
const handleFetchReport=()=>{
    
setIsClick(true);
const data={
'salesman':type==='Admin'?defaultsalesman:userId,
'sector':defaultsector,
'date':manualdate,
'dateto':manualdateto


}


axios({
    url: 'api/post/batch/invoices',
    method: 'POST',
    responseType: 'blob', 
    data:data
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    window.open(url, '_blank');
    
   setIsClick(false);
  });


}
  return (
    <div className='row fadeInDown animated'>
      <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>BATCH INVOICES</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel label="Enter Date From">
        <Form.Control type="date" value={manualdate} onChange={(e)=>setManualDate(e.target.value)} name="manualdate" placeholder="Enter Manual Date"  />
      </FloatingLabel>
     
    </div>
    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Enter Date To">
        <Form.Control type="date" value={manualdateto} onChange={(e)=>setManualDateTo(e.target.value)} name="manualdate" placeholder="Enter Manual Date"  />
      </FloatingLabel>
     
    </div>

    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Select Region Name ">
   
      <Form.Select aria-label="Sector Name"  value={defaultsector}  name='sector_id' onChange={(e)=>setDefaultSector(e.target.value)}>
      {sector && sector.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>

    <div className='col-md-10 mt-4'>
      
    <FloatingLabel  label="Sales Associate ">
   
      <Form.Select aria-label="Salesman Name" value={defaultsalesman}   name='salesman_id' onChange={(e)=>setDefaultSalesman(e.target.value)}>
      {salesman && salesman.map(item => (
     
        <option key={item.id} value={item.id}>
        {item.name} </option>
     
  
    
    
    ))}
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-2 mt-4'>

<div className='col md'>
<button onClick={handleFetchReport} className='btn btn-success btn-lg  btn-block'>{isClick?  <Puff width={30} height={26} stroke='red'/>:'Save Report'}  </button>

</div>

    </Row></div>
    </div></center>: 
    
    
    
    <center></center>
    </div>
  )
}

export default BatchListInvoices
