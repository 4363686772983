import React from 'react'
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis } from 'recharts';

function ProductChart(props) {
  return (
    <div style={{marginLeft:'-120px'}}>
       <RadarChart   cx={300} cy={200} outerRadius={150} width={500} height={500} data={props.data}>
      <PolarGrid />
      <PolarAngleAxis dataKey="productname" />
      <PolarRadiusAxis angle={30} domain={[0, 150]} />
      <Radar name="ItemSold" dataKey="totalqty" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
      <Legend />
    </RadarChart>
    </div>
  )
}

export default ProductChart
