import React from 'react'
import { useStateContext } from '../Context/ContextProvider'

function PrintPurchaseInvoice(props) {

 const {currency}= useStateContext();
  return (
   
<div id="thermalpospurcahsecosmetics">
    

<div style={{textAlign:'center',marginTop:'-5px'}}>
  <h2 style={{marginBottom:'-15px'}}>Purchase Invoice</h2>
  <h4 >{props.data.customer.name}</h4>
</div>


<div style={{display:'flex',justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
  <div style={{padding:'10px'}}>
    <div style={{marginBottom:'10px'}}>
     <b>Name</b>:<span id="customername"></span> 
    </div>
  <div style={{marginBottom:'8px'}}>
   <b>Address</b>:<span id="customerlocation">{props.data.customer.city}</span>
    </div>
     <div style={{marginBottom:'8px'}}>
    <b>Contact</b>:<span id="customerphone">{props.data.customer.phone}</span>
    </div>

   
  </div>
  <div style={{padding:'10px'}}>
    
     <div style={{marginBottom:'8px'}}>
     <b>Invoice</b>:<span id="rinvoiceno">{props.data.invoiceno}</span> 
    </div>

     

     <div style={{marginBottom:'8px'}}>
     <b>Type</b>:<span id="paytype">Credit/Cash</span> 
    </div>
  </div>
</div>



<div>

  <div style={{paddingRight:'10px',paddingLeft:'10px',marginTop:'1px',marginLeft:'10px',marginRight:'10px'}}>
    
    
  
     <table style={{width:'100%',fontSize:'12px'}}>
        <thead >
              <tr>
 <th  style={{textAlign:'left',border:'1px solid #dddddd'}}>Sr#</th>

                        <th style={{textAlign:'left',border:'1px solid #dddddd'}}>Item Name</th>
                        <th  style={{textAlign:'left',border:'1px solid #dddddd',display:'none'}}>Cotton</th>
                        <th style={{textAlign:'left',border:'1px solid #dddddd',display:'none'}}>Case</th>
                        <th style={{textAlign:'left',border:'1px solid #dddddd'}}>Qty</th>

                        <th  style={{textAlign:'left',border:'1px solid #dddddd'}}>Unit</th>
                         <th  style={{textAlign:'left',border:'1px solid #dddddd'}}>Weight</th>
                          
                        <th  style={{textAlign:'left',border:'1px solid #dddddd'}}>Rate</th>
                        <th   style={{textAlign:'left',border:'1px solid #dddddd'}}>Gst</th>
                         <th  style={{textAlign:'left',border:'1px solid #dddddd'}}>Dis</th>
                         <th   style={{textAlign:'left',border:'1px solid #dddddd'}}>ws. Margin</th>
                         <th   style={{textAlign:'left',border:'1px solid #dddddd'}}>Ret.Margin</th>
                        <th   style={{textAlign:'left',border:'1px solid #dddddd'}}>Amount</th>
                       
                    </tr>

        </thead>

        <tbody id="printcartreciept">
          {props.data.cart && props.data.cart.map((item,index)=>(


          
        <tr key={item.id}>
 <td style={{textAlign:'left',border:'1px solid #dddddd'}}>{index+1}</td>

                        <td  style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.name}</td>
                        <td style={{textAlign:'left',border:'1px solid #dddddd',display:'none'}}>{item.pack_qty}</td>
                        <td style={{textAlign:'left',border:'1px solid #dddddd',display:'none'}}>{item.pack_size}</td>
                        <td  style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.qty}</td>

                        <td  style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.unit}</td>
                         <td  style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.weight}</td>
                          
                        <td  style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.unitcostprice}</td>
                        <td   style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.gst}</td>
                         <td  style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.discount}%({item.totaldiscount})</td>
                         <td   style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.totalwholesalemargin}</td>
                         <td   style={{textAlign:'left',border:'1px solid #dddddd'}}>{item.retailmargin}</td>
                        <td   style={{textAlign:'left',border:'1px solid #dddddd'}}>{currency}{(item.unitcostprice*item.qty).toFixed(2)}</td>
                       
                    </tr>
))}

        </tbody>
      
    </table>

  </div>
  
     <div style={{marginLeft:'20px',marginRight:'20px',padding:'15px',display:'flex',justifyContent:'space-between',marginTop:'20px',fontSize:'15px'}}>
      
          
        

          <div>
               
    <div style={{textAlign:'left'}}>
 Amount Exc.Tax={currency}<span id="rsupergrandtotal">{parseFloat(props.data.totalwithgst).toFixed(2)}</span>
    </div>
    <div style={{textAlign:'left'}}>
   Invoiced Sales Tax= {currency}<span id="rsale_tax">{props.data.totalgst}</span>
    </div>

    <div style={{textAlign:'left'}}>
   Amount incl. Tax= {currency}<span id="rsupergrandtotalwithsalestax">{parseFloat(parseFloat(props.data.totalwithgst)+parseFloat(props.data.totalgst)).toFixed(2)}</span>
    </div>
   
    <div style={{textAlign:'left'}}>
       W/S Margin= {currency}<span id="wsmargin">{parseFloat(props.data.totalwholesalemargin)}</span>
    </div>
    <div style={{textAlign:'left'}}>
       Retail Margin= {currency}<span id="retailmargin">{parseFloat(props.data.totalretailmargin)}</span>
    </div>
      <div style={{textAlign:'left'}}>
       Discount= {currency}<span id="rtotaldiscount">{props.data.totaldiscount}</span>
    </div>
     <div style={{textAlign:'left'}}>
      Payment= {currency}<span id="rpayment">{props.data.totalpaid}</span>
    </div>
    
     <div style={{textAlign:'left'}}>
        Invoiced Payable={currency}<span id="rbalance">{parseFloat((parseFloat(props.data.totalwithgst)-parseFloat(props.data.totaldiscount)-parseFloat(props.data.totalretailmargin)-parseFloat(props.data.totalwholesalemargin)-parseFloat(props.data.totalpaid)+parseFloat(props.data.totalgst))).toFixed(2)}</span>
    </div>


     <div style={{textAlign:'left'}}>
        Pre Balance={currency}<span id="pbalance">{(props.data.pbalance).toFixed(2)}</span>
    </div> 
     <div style={{textAlign:'left'}}>
        Total Payable={currency}<span id="totaldue">{parseFloat((parseFloat(props.data.totalwithgst)-parseFloat(props.data.totaldiscount)-parseFloat(props.data.totalretailmargin)-parseFloat(props.data.totalwholesalemargin)-parseFloat(props.data.totalpaid)+parseFloat(props.data.pbalance)+parseFloat(props.data.totalgst))).toFixed(2)}</span>
    </div>
          </div>
       </div>


    
</div>


</div>
  )
}

export default PrintPurchaseInvoice
