import axios from 'axios';
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useStateContext } from './Context/ContextProvider';

function Signup() {
const {setUser,setToken,setType,setUserId}=useStateContext();
  const [registerInput,setRegisterInput]=useState({
    name:'',
email:'',
password:'',
error_list:[]

});
const handleInput=(e)=>{

e.persist();
setRegisterInput({...registerInput,[e.target.name]:e.target.value});

}
const handleSubmit=(e)=>{

e.preventDefault();
const data={
email:registerInput.email,
password:registerInput.password,
name:registerInput.name

}

axios.get('/sanctum/csrf-cookie').then(res => {

axios.post(`/api/register`,data).then(res=>{

if(res.data.status===200){

setUser(res.data.username);
setToken(res.data.token);
setType(res.data.type);
setUserId(res.data.id);

}else{

  setRegisterInput({...registerInput,error_list:res.data.validation_errors})

}

});});
}

  return (
    <div className="login-signup-form animated fadeInDown">
    <div className="form">
      <form onSubmit={handleSubmit}>
        <h1 className="title">Signup for Free</h1>
      <div >
      <span style={{color:"red"}}>{registerInput.error_list.name}</span><br></br>
      <span style={{color:"red"}}>{registerInput.error_list.email}</span><br></br>
      <span style={{color:"red"}}>{registerInput.error_list.password}</span>

      </div>
          
        
        <input className='cinput'  type="text" value={registerInput.name} onChange={handleInput} placeholder="Full Name" name='name'/>
        <input className='cinput' type="email" value={registerInput.email} onChange={handleInput} placeholder="Email Address" name='email'/>
        <input className='cinput' type="password" value={registerInput.password} onChange={handleInput} placeholder="Password" name='password'/>
        <button className="bttn btn-block">Signup</button>
        <p className="message">Already registered? <Link to="/login">Sign In</Link></p>
      </form>
    </div>
  </div>
  )
}

export default Signup
