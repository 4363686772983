import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';

function GstCustomer() {
    const [datefrom,setDateFrom]=useState('');
    const [dateto,setDateTo]=useState('');
    const [isClick,setIsClick]=useState(false);
    const [defaultsector,setDefaultSector]=useState('');
    const [sector,setSector]=useState([]);
    const [defaultsalesman,setDefaultSalesman]=useState('');
    const [salesman,setSalesman]=useState([]);
    useEffect(()=>{
        axios.get('/api/getsectors').then(res=>{
        
        setSector(res.data.sectors);
        setDefaultSector(res.data.sectors[0]['id']);
        
        })
        axios.get('/api/getsalesman').then(res=>{
    
          setSalesman(res.data.salesman);
          setDefaultSalesman(res.data.salesman[0]['id']);
          
          })
        
        },[])
const handleSubmit=(e)=>{
e.preventDefault();
setIsClick(true);
const data={
'datefrom':datefrom,
'dateto':dateto,
'sectorid':defaultsector,
'salesmanid':defaultsalesman,

}
axios({
  url: 'api/gst/customer/report',
  method: 'POST',
  data:data,
  responseType: 'blob', // important
}).then((response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'customersgst.xlsx'); // or any other filename you prefer
    document.body.appendChild(link);
    link.click();
setIsClick(false);
});

}

  return (
    <div className='row fadeInDown animated'>
       <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>GST CUSTOMER TAX</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
  
    <FloatingLabel  label="Date From" >
        <Form.Control type="date"  name="datefrom" placeholder='Date From'  value={datefrom} onChange={(e)=>setDateFrom(e.target.value)} />
      </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>
  
    <FloatingLabel  label="Date To" >
        <Form.Control type="date"  name="dateto" placeholder='Date To'  value={dateto} onChange={(e)=>setDateTo(e.target.value)} />
      </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Select Sector Name ">
   
      <Form.Select aria-label="Sector Name"  value={defaultsector}  name='sector_id' onChange={(e)=>setDefaultSector(e.target.value)}>
      {sector && sector.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>
    <FloatingLabel  label="Sales Associate">
   
      <Form.Select aria-label="Salesman Name" value={defaultsalesman}   name='salesman_id' onChange={(e)=>setDefaultSalesman(e.target.value)}>
      
      {salesman && salesman.map(item => (
        
  <option key={item.id} value={item.id}>
    {item.name} </option>))}
      </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-1 mt-4'>

<div className='col md'>
<button onClick={(e)=>handleSubmit(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff />:'Download Report'}  </button>

</div>
    </Row></div>
    </div></center>
    </div>
  )
}

export default GstCustomer

