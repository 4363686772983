import React from 'react'

function User() {
  return (
    <div>
      User
    </div>
  )
}

export default User
