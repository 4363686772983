import axios from 'axios';
import React, {  useEffect, useState } from 'react'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Table from 'react-bootstrap/Table';
import '../Customcss/table.css'




import Puff from 'react-loading-icons/dist/esm/components/puff';
import { Row } from 'react-bootstrap';
import { useStateContext } from '../Context/ContextProvider';
import { toast } from 'react-toastify';

function Category() {
const [isClick,setIsClick]=useState(false);
const {type}=useStateContext();
    const [name,setName]=useState({

        name:'',
        
    });
    const [data,setData]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState('');
    const [serialStart, setSerialStart] = useState('');
    
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    setSerialStart((currentPage - 1) * itemsPerPage + 1);
  }, [currentPage, itemsPerPage]);
  
    const handleInput=(e)=>{

e.preventDefault();
setName({...name,[e.target.name]:e.target.value});

    }
const submitCategory=(e)=>{
  setIsClick(true);
e.preventDefault();
const data={
name:name.name

}
axios.get('/sanctum/csrf-cookie').then(res=>{
axios.post('api/post/add/category',data).then(res=>{

  toast.success(res.data.message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  

setIsClick(false);
})

})


}


  
    useEffect(()=>{
      axios.get(`/api/view/category?page=${currentPage}&search=${searchQuery}`).then(response=>{
       setItemsPerPage(response.data.category.per_page);
        setData(response.data.category.data);
        setTotalPages(response.data.category.last_page);
      })
     
      
    
    },[currentPage,searchQuery,isClick]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
      };
const handleDelete=(e,id)=>{

 setIsClick(true);

  
axios.delete(`api/delete/category/${id}`).then(res=>{

if(res.data.status===200){
  toast.danger(res.data.message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  
setIsClick(false);
}else{


}

})

}
const [editCategory,setEditCategory]=useState('');
const handleEdit=(e,id)=>{

setEditCategory(id);

axios.get(`api/edit/category/${id} `).then(response=>{
console.log(response.data.category.name);
  setName({...name,'name':response.data.category.name});

})


}

const submitUpdate=(e)=>{
setIsClick(true);

e.preventDefault();
const data={
name:name.name,
id:editCategory

}

axios.get('/sanctum/csrf-cookie').then(res=>{
axios.put('api/update/category',data).then(res=>{
if(res.data.status===200){
  toast.success(res.data.message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
  
  setIsClick(false);

}else{
  setIsClick(false);

  toast.danger('something went wrong', {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
}


})

})

}

  return (
    <div className="row fadeInDown animated" >

    
    {editCategory?
    <center>
        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Edit Category</h2> <hr/>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel controlId="categoryname" label="Edit Category Name">
        <Form.Control type="text" name="name" placeholder="Edit Category Name" value={name.name} onChange={handleInput} />
      </FloatingLabel>
     
    </div>
    <div className='col-md-10 mt-4'>

    <Row className='g-2 mt-4'>

<div className='col md'>
<button onClick={submitUpdate} className='btn btn-success btn-lg  btn-block'>{isClick?  <Puff height={25} width={30}/>:'Update'}  </button>

</div>
<div className='col md'>
<button onClick={(e)=>setEditCategory('')} className='btn btn-info btn-lg btn-block'>Add New  </button>

</div>
    </Row></div>
    </div></center>: 
    
    
    
    <center>


        <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

        <div className='co-md-11'>
<h2>Add Category</h2><hr></hr>
            </div>   
       
    <div className='col-md-10 mt-4'>
    <FloatingLabel controlId="categoryname" label="Category Name">
        <Form.Control type="text" name="name" placeholder="Enter Category Name" value={name.name} onChange={handleInput} />
      </FloatingLabel>
    </div>
    <div className='col-md-10 mt-4'>
<button onClick={submitCategory} className='btn btn-success btn-lg btn-block'>{isClick?  <Puff height={25} width={30}/>:'Save'}  </button>
    </div>
    </div></center>}
<div className='container-fluid'  >
<div className='row' style={{padding:'30px'}}>

<div className='col-md-12' style={{ height: 'auto', overflow: 'auto',border:'1px solid lightgray  ',padding:'15px'}}>
<input type="text"  placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />
    
    <Table striped bordered hover size="sm" >
    <thead>
        <tr>
        <th>Sr</th>
        <th>Name</th>
        <th>Delete</th>
            <th>Update</th>
        </tr>
    </thead>
  <tbody>
 {data && data.map((item,index)=>(
 <tr key={item.id} 
 >

    <td>{serialStart+index}</td>
    <td >{item.name}</td>
<td><button className='btn btn-danger' disabled={type!=='Managment'?true:false} onClick={(e) => handleDelete(e,item.id)} style={{marginRight:'3px'}}><FaTrash style={{marginBottom:'3px'}}/></button> </td>
        
        <td>
        <button onClick={(e)=>handleEdit(e,item.id)} className='btn btn-success '><FaEdit style={{marginBottom:'3px'}}/></button> 
        </td>
      
       </tr>
      
 ))
    
}</tbody>  

</Table>
 

   
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>
</div></div></div>

    </div>
  )
}

export default Category
