import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

function AddUserModel(props) {
    const [inputData, setInputData] = useState({

name:'',
phone:''

    });
    

    const handleSubmit = (e) => {
      e.preventDefault();
      props.onChildSubmit(inputData);
      setInputData('');
    };
  
    const handleInputChange = (e) => {
      setInputData({...inputData,[e.target.name]:e.target.value});
    };

 const handleClose=(e)=>{
    e.preventDefault();

props.onChildClose(false);
 }
 

  return (
    <>
     

      <Modal show={props.show} onHide={handleClose}  >
        <Modal.Header closeButton>
          <Modal.Title>Add New Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Customer Name"
                value={inputData.name} onChange={handleInputChange}
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Phone"
                value={inputData.phone} onChange={handleInputChange}
                autoFocus
              />
            </Form.Group>
           
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddUserModel